import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { scroller  } from 'react-scroll';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../assets/img/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faLinkedin, faSquareInstagram, faSquareYoutube } from '@fortawesome/free-brands-svg-icons';
import { faAt, faBarsStaggered, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

export default function Header() {
  
  const [navClicked, setnavClicked] = useState(null);
  const [navOffset, setnavOffset] = useState(null);
  const [trimSlash, settrimSlash] = useState(null);
  const [activeKey, setactiveKey] = useState(null);

 

  window.addEventListener('scroll', () => {
    const element = document.getElementById('brand');
    if(window.scrollY >= 50){
      element.classList.add("sticky-brand");
    }else{
      element.classList.remove("sticky-brand");
    }
  });

  const urlTrim = () => {
    if(!navClicked){
      let path = window.location.hash;
      let trimExt = path.replace(/\.[^/.]+$/, "");
      let trimSlash = trimExt.replace(/#/g, "");
      if(trimSlash !== "") {
        if(trimSlash === "digital-signage") {
          setnavOffset(-120);
        }else{
          setnavOffset(-10);
        }
        settrimSlash(trimSlash);
        setactiveKey(trimSlash);
      }
    }
  }


  const handleNavigation = (key) => {
    settrimSlash(null);
    if(key === "digital-signage") {
      setnavOffset(-120);
    }else{
      setnavOffset(-10);
    }
    setnavClicked(key);
    setactiveKey(key);
  }

  function helmetSwitch() {
    switch(activeKey) {
      case 'digital-signage': return (
        <Helmet>
          <title>Digial Signage | AWTOX</title>
          <meta name="description" content="Customized OS and Branded signage app for starting your own advertisng media business." />
          <link rel="canonical" href="https://www.awtox.com/digital-signage" />
        </Helmet>
      )
      case 'consumer-api': return (
        <Helmet>
          <title>Consumer API | AWTOX</title>
          <meta name="description" content="India's Largest B2b and B2c Service Provider. All major API services for you." />
          <link rel="canonical" href="https://www.awtox.com/consumer-api" />
        </Helmet>
      )
      case 'legal-services': return (
        <Helmet>
          <title>Legal Services | AWTOX</title>
          <meta name="description" content="Company registerations, Tax services, Patient services and more for your business activities." />
          <link rel="canonical" href="https://www.awtox.com/legal-services" />
        </Helmet>
      )
      case 'domain-registration': return (
        <Helmet>
          <title>Domain, Email, Google Workspace, Servers, Wordpress and eCommerce Hosting | AWTOX</title>
          <meta name="description" content="We provide domain registerstions and web server hosting for all businesses with 24/7 customer care support and technology advisory." />
          <link rel="canonical" href="https://www.awtox.com/domain-registeration" />
        </Helmet>
      )
      case 'ott-platform': return (
        <Helmet>
          <title>Fully Whitelabled OTT Platform | AWTOX</title>
          <meta name="description" content="Launch new OTT Platform. Rent, Broadcast & Stream Your Own Branded OTT Platform." />
          <link rel="canonical" href="https://www.awtox.com/ott-platform" />
        </Helmet>
      )
      case 'application-development': return (
        <Helmet>
          <title>Application Development for all business and services  | AWTOX</title>
          <meta name="description" content="Business Portfolio, eCommerce, Billing Applications, Travel, Service, Grocery, Food Delivery" />
          <link rel="canonical" href="https://www.awtox.com/application-development" />
        </Helmet>
      )
      case 'whatsapp-business': return (
        <Helmet>
          <title>Whatsapp Business for client service and business marketing | AWTOX</title>
          <meta name="description" content="Easy manage your customer service and product update with whatsapp business services from Meta | Facebook" />
          <link rel="canonical" href="https://www.awtox.com/whatsapp-business" />
        </Helmet>
      )
      default:
        return (
          <Helmet>
            <title>AWTOX | WhatsApp Business Platform, Digital Signage Media, Web Application, Company Registration, OTT Platform</title>
            <meta name="description" content="We are experts in Artificial Intelligence, Cloud Mobile Technologies. Digital Signage, OTT Platform, Consumer API's, Domains & Hosting Service, Legal Service..." />
            <link rel="canonical" href="https://www.awtox.com/" />
          </Helmet>
        )
    }
  }
  
  urlTrim();
  useEffect(() => {
    // console.log('Clicked:',navClicked);
    // console.log('offset:', navOffset);
    // console.log('url:', trimSlash);
    // console.log('activeKey:', activeKey);
    
    scroller.scrollTo(activeKey, {
      duration: 100,
      smooth: true,
      offset: navOffset
    })
  }, [activeKey, navOffset, trimSlash]);

  return (
    <>
       <Navbar className="topNav">
            <Container>
              <Nav className="top-menu menu-support justify-content-end flex-grow-1">
                      <Nav.Link href="mailto:enquiry@awtox.com" tabIndex='1' className="px-0"><FontAwesomeIcon icon={faEnvelope} /> enquiry<FontAwesomeIcon icon={faAt} />awtox.com</Nav.Link>
                      <Nav.Link href="#">|</Nav.Link>
                      <Nav.Link href="https://www.awtox.com/myaccount/login" tabIndex='2' className="px-0"><FontAwesomeIcon icon={faUser} /> My Account</Nav.Link>
              </Nav>
              <Nav className="top-menu menu-social justify-content-end d-none d-md-inline-block">
                      <Nav.Link href="https://www.facebook.com/awtox" target="_blank" eventKey="link-3" tabIndex='3'><FontAwesomeIcon icon={faFacebookSquare} /></Nav.Link>
                      <Nav.Link href="https://www.twitter.com/awtox_com" target="_blank" eventKey="link-4" tabIndex='4'><FontAwesomeIcon icon={faTwitterSquare} /></Nav.Link>
                      <Nav.Link href="https://www.linkedin.com/company/awtox" target="_blank" eventKey="link-5" tabIndex='5'><FontAwesomeIcon icon={faLinkedin} /></Nav.Link>
                      <Nav.Link href="https://www.instagram.com/awtox_com" target="_blank" eventKey="link-6" tabIndex='6'><FontAwesomeIcon icon={faSquareInstagram} /></Nav.Link>
                      <Nav.Link href="https://www.youtube.com/channel/UC0KL5WoHLGAONsLnntp8l0Q" target="_blank" eventKey="link-6" tabIndex='7'><FontAwesomeIcon icon={faSquareYoutube} /></Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          <Navbar id="navMenu" key="sm" expand="lg" sticky="top">
            <Container>
              <Navbar.Brand href="/" id="brand">
                  <img className='logoBrand' src={logo} alt="AWTOX" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="mainMenu" ><FontAwesomeIcon icon={faBarsStaggered} /></Navbar.Toggle>
              <Navbar.Offcanvas id="mainMenu" aria-labelledby="mainMenu" placement="end" scroll={true} backdrop={true} >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      Menu
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="main-menu justify-content-end flex-grow-1 pe-3" activeKey={activeKey ? activeKey : "digital-signage"} onSelect={handleNavigation} >
                      <Nav.Link href="#" eventKey="digital-signage">Digital Signage</Nav.Link>
                      <Nav.Link href="#" eventKey="consumer-api">Consumer API</Nav.Link>
                      <Nav.Link href="#" eventKey="legal-services">Legal Services</Nav.Link>
                      <Nav.Link href="#" eventKey="domain-registration">Domains</Nav.Link>
                      <Nav.Link href="#" eventKey="ott-platform">OTT Platform</Nav.Link>
                      <Nav.Link href="#" eventKey="application-development">Applications</Nav.Link>
                      <Nav.Link href="#" eventKey="whatsapp-business">Whatsapp Business</Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>

          {activeKey && (helmetSwitch()) }
    </>
  )
}














