import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import logo from '../assets/img/logo.svg';

const Whatsapp = () => {
	return (
		<WhatsAppWidget
			phoneNo="918089011710"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="200px"
			autoOpen={false}
			autoOpenTimer={10000}
			messageBox={false}
			messageBoxTxt='I need services for ... "WhatsApp Business Platform"'
			iconSize="60"
			iconColor="white"
			iconBgColor="#008318"
			headerIcon={logo}
			headerIconColor="pink"
			headerTxtColor="#fff"
			headerBgColor="#222222"
			headerTitle="Support Team"
			headerCaption="Online"
			bodyBgColor="#fff7e8"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="#d4b27b"
			btnBgColor="#222222"
			btnTxtColor="#fff"
			btnTxt="Start Chat"
		/>
	);
};

export default Whatsapp;