import React from 'react';
import {Container, Row, Col, Accordion, Nav } from 'react-bootstrap';

function importH(r) {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
const images = importH(require.context('../assets/img/apiservices', false, /\.(png)$/));

class Consumerapi extends React.Component {
    render() {
      return (
        <>     
        
        <div id="consumer-api" className='section'>
                <Container>
                  <Row>
                  <Col xs={12} lg={7} className="apiservices mb-4 mt-5 pt-4">
                        <h1>
                          <strong>CONSUMER API SERVICES</strong>
                        </h1>
                        <h2>India's Largest <span className='coloured'>B2b</span> and <span className='coloured'>B2c</span> Service Provider</h2>
                        <h3>Our API integration is so simple & Easy. Quality Service. Safe & Secure Platform.</h3>
                        <p>
                           Pay bills, Recharge, Send Money, Buy Gold, Purchase Gift Voucher, Book Flight, Book Hotel & Use Our Seamless API.
                        </p>
                        <div id='apis' className='mb-4'>
                          <Row >
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['mobile_payments.png']} alt="img" />
                                 <span>Mobile Payment</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['electricity_bill.png']} alt="img" />
                                 <span>Electricity Bill</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['gas_bill.png']} alt="img" />
                                 <span>Gas Bills</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['dtn_payments.png']} alt="img" />
                                 <span>Dth Payment</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['loan_payment.png']} alt="img" />
                                 <span>Loan Repayment</span>
                               </div>
                            </Col>
                          </Row>
                          <Row> 
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['water_bill.png']} alt="img" />
                                 <span>Water Bills</span>
                               </div>
                            </Col>                           
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['gift_vouchers.png']} alt="img" />
                                 <span>Gift Vouchers</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['broadband_payments.png']} alt="img" />
                                 <span>Broadband Payments</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['insurance_payments.png']} alt="img" />
                                 <span>Insurance Payments</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['gold_investments.png']} alt="img" />
                                 <span>Gold Investments</span>
                               </div>
                            </Col>                            
                          </Row>
                          <Row>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['flight_bookings.png']} alt="img" />
                                 <span>Flight Bookings</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['bus_bookings.png']} alt="img" />
                                 <span>Bus Bookings</span>
                               </div>
                            </Col>                            
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['car_bookings.png']} alt="img" />
                                 <span>Car Bookings</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['train_bookings.png']} alt="img" />
                                 <span>Train Bookings</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['fasttag_payments.png']} alt="img" />
                                 <span>FastTag Payments</span>
                               </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    <Col xs={12} lg={5} className='apihashes'>
                        <Row>
                          <Accordion defaultActiveKey="0" className="mt-5 mb-5">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>RECHARGE SERVICES API</Accordion.Header>
                              <Accordion.Body>
                                  <Row>
                                    <Col>
                                      <Nav as="ul" className="service-block justify-content-center">
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Mobile Recharge</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Postpaid Recharge</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Dth Recharge</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Fast Tag Recharge</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                  </Row>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                              <Accordion.Header>BILL PAYMENT SERVICES API</Accordion.Header>
                              <Accordion.Body>
                                  <Row>
                                    <Col>
                                      <Nav as="ul" className="service-block justify-content-center">
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Electricity Bill Payment</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Gas Bill Payment</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Water Bill Payment</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Broadband Bill Payment</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Landline Bill Payment</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Insurance Premium Payment</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Loan Repayment</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                  </Row>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                              <Accordion.Header>TRAVEL BOOKING SERVICES API</Accordion.Header>
                              <Accordion.Body>
                                  <Row>
                                    <Col>
                                      <Nav as="ul" className="service-block justify-content-center">
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Flight Booking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Hotel Booking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Train Booking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Bus Booking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Car Booking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Bike Booking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Activities</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Holiday</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Adventure</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                  </Row>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                              <Accordion.Header>GIFT/BRAND VOUCHERS  API</Accordion.Header>
                              <Accordion.Body>
                                  <Row>
                                    <Col>
                                      <Nav as="ul" className="service-block justify-content-center">
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Brand Vouchers</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Amazon Gift Cards</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Flipkart Coupons</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Gift Vouchers</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                  </Row>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                              <Accordion.Header>MORE SERVICES API</Accordion.Header>
                              <Accordion.Body>
                                  <Row>
                                    <Col>
                                      <Nav as="ul" className="service-block justify-content-center">
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Digital Gold</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#Gas Booking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                          <Nav.Link href="#">#LIC Premium</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                  </Row>
                              </Accordion.Body>
                            </Accordion.Item>

                          </Accordion>
                        </Row>
                    </Col>                    
                  </Row>
                </Container>
            </div>  
    
        </>
      );
    }
  }

  export default Consumerapi;

