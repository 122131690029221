import React from 'react';
//import ReactPlayer from 'react-player'
import {Container, Row, Col } from 'react-bootstrap';
import borderBottom from '../assets/img/dark-edges-bottom.svg';
import introV from '../assets/videos/introV.mp4';


function importH(r) {
  let images = {};
  r.keys().map((item) => ( images[item.replace('./', '')] = r(item) ) );
  return images;
}
const images = importH(require.context('../assets/img/signage', false, /\.(png)$/));



class Digitalsignages extends React.Component {
    render() {
      return (
        <>
        <div id="digital-signage" className="section dark">
            <Container>
                <Row>
                    <Col xs={12} sm={12} lg={7} className="mb-4 mt-5">
                        <h1>
                          <strong>DIGITAL SIGNAGE INFRASTRUCTURE</strong>
                        </h1>
                        <h2>Customized <span className='coloured'>OS</span> and <span className='coloured'>Branded</span> signage app for starting your own advertisng media.</h2>
                        <h3>An end-to-end infrastructure for operating digital signage devices at scale.</h3>
                        <p style={{fontSize: 20}}>
                           Start with the hardware of your choice, manage it from a cloud based application, have zero-touch deployments, based on universal application development. With AWTOX operating your digital signage endpoints is a breeze.
                        </p>
                        <div id='signage' className='mb-4'>
                          <Row >
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['admin_app.png']} alt="img0" />
                                 <span>Web Admin<br />Application</span>
                               </div>
                            </Col> 
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['signage_app.png']} alt="img1" />
                                 <span>Screen Play<br />Application</span>
                               </div>
                            </Col>
                            
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['web_app.png']} alt="img2" />
                                 <span>Web Clients<br />Application</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['client_mobile.png']} alt="img3" />
                                 <span>Mobile Client<br />Application</span>
                               </div>
                            </Col>                        
                          </Row>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} lg={5}>
                          {/* 
                          <Carousel className="mt-5 text-center signage-slide">
                              <Carousel.Item>
                                <img className="img-fluid" src={images['slide-01.png']} alt="img" />
                                <Carousel.Caption>
                                  <h3>Shopping Mall Signages</h3>
                                </Carousel.Caption>
                              </Carousel.Item>
                              <Carousel.Item>
                                <img className="img-fluid" src={images['slide-02.png']} alt="img" />
                                <Carousel.Caption>
                                  <h3>Food Court Signages</h3>
                                </Carousel.Caption>
                              </Carousel.Item>
                              <Carousel.Item>
                                <img className="img-fluid" src={images['slide-03.png']} alt="img" />
                                <Carousel.Caption>
                                  <h3>Walk Way SIgnages</h3>
                                </Carousel.Caption>
                              </Carousel.Item>
                              <Carousel.Item>
                                <img className="img-fluid" src={images['slide-04.png']} alt="img" />
                                <Carousel.Caption>
                                  <h3>Bus Stand Signages</h3>
                                </Carousel.Caption>
                              </Carousel.Item>
                              <Carousel.Item>
                                <img className="img-fluid" src={images['slide-05.png']} alt="img" />
                                <Carousel.Caption>
                                  <h3>Parking Area Signages</h3>
                                </Carousel.Caption>
                              </Carousel.Item>
                            </Carousel>
                          */}
                          <div id="videowrapper">
                            <video className="video" autoPlay controls>
                              <source 
                                src={introV}  
                                type="video/mp4"  
                                autoPlay                                                            
                              />
                            </video>
                          </div>                          
                      </Col>
                </Row>
            </Container>
            <img className='border-bottom-dark invert' src={borderBottom} alt="img01" />
        </div>
        
        </>
      );
    }
  }

  export default Digitalsignages;

