import React from 'react';
import {Container, Row, Col, Carousel  } from 'react-bootstrap';
import borderTop from '../assets/img/dark-edges-top.svg';
import borderBottom from '../assets/img/dark-edges-bottom.svg';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => ( images[item.replace('./', '')] = r(item) ));
  return images;
}

const images = importAll(require.context('../assets/img/ottplatform', false, /\.(png)$/));

class Ottplatform extends React.Component {
    render() {
      return (
        <>  
          <div id="ott-platform" className='section dark'>
          <img className='border-top-dark' src={borderTop} alt="borderTop" />
                <Container>
                <Row>
                    <Col xs={12} lg={7} className='ottplatform'>
                        <h1 className='mt-5'>
                        OTT PLATFORM
                        </h1>
                        <h2>Launch new OTT Platform. <span className='coloured'>Rent</span>, <span className='coloured'>Broadcast</span> & <span className='coloured'>Stream</span> Your Own <span className='coloured'>Branded OTT Platform</span>.</h2>
                        <h3>Robust & scalable end-to-end platform to build your subscribers and monetize your content.</h3>
                        <p style={{fontSize: 20}}>
                          Kick start your OTT Platform with our expertise support and professional managment solution from AWTOX experts.
                        </p>
                        <p style={{fontSize: 20}}>
                            Video Streaming, Audio Streaming, Live Streaming, Video Content Managment, Monetization, Video Player, Cloud Transcoding, DRM & Security, Video Marketing, Report & Analytics, Mobile, Web and TV Apps.
                        </p>
                        <div id='platform'>
                          <Row >
                            <Col>
                               <div className="list">
                                 <img className="img-fluid" src={images['apple-tv-logo.png']} alt="Apple Tv" />
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img-fluid" src={images['Android_tv_logo.png']} alt="Android TV" />
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img-fluid" src={images['amazon-fire-tv-logo.png']} alt="Fire Stick" />
                               </div>
                            </Col>                  
                          </Row>
                          <Row >                            
                            <Col>
                               <div className="list">
                                 <img className="img-fluid" src={images['roku-logo.png']} alt="Roku" />
                               </div>
                            </Col> 
                            <Col>
                               <div className="list">
                                 <img className="img-fluid" src={images['chrome-cast-logo.png']} alt="Chrome Cast" />
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img-fluid" src={images['android.png']} alt="Android" />
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img-fluid" src={images['ios-app.png']} alt="IOS" />
                               </div>
                            </Col>                    
                          </Row>
                          <Row>
                          <Col>
                              <div className="list">
                                 <img className="img-fluid" src={images['lg-tv-logo.png']} alt="LG TV" />
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img-fluid" src={images['samsungsmarttv.png']} alt="Samsung TV" />
                               </div>
                            </Col>                     
                          </Row>
                        </div>
                      </Col>
                      <Col>
                          <Carousel className="mt-5">
                            <Carousel.Item>
                              <img className="d-block w-100" src={images['web.png']} alt="Web Panel" />
                              <Carousel.Caption>
                                <h3>Web Panel</h3>
                              </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                              <img className="d-block w-100" src={images['mobile.png']}alt="Mobile Panel" />
                              <Carousel.Caption>
                                <h3>Mobile Panel</h3>
                              </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                              <img className="d-block w-100" src={images['tablet.png']}alt="Tablet Panel" />
                              <Carousel.Caption>
                                <h3>Tablet Panel</h3>
                              </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                              <img className="d-block w-100" src={images['smart_tv.png']}alt="Smart TV" />
                              <Carousel.Caption>
                                <h3>Smart TV</h3>
                              </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                              <img className="d-block w-100" src={images['console.png']}alt="XBox & Playstation" />
                              <Carousel.Caption>
                                <h3>XBox & Playstation</h3>
                              </Carousel.Caption>
                            </Carousel.Item>
                          </Carousel>
                      </Col>
                </Row>
                </Container>
                <img className='border-bottom-dark invert' src={borderBottom} alt="borderBottom" />
            </div>
            
        </>
      );
    }
  }

  export default Ottplatform;

