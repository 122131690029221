import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Digitalsignages from './components/Digitalsignages';
import Legalservices from './components/Legalservices';
import Consumerapi from './components/Consumerapi';
import Domainregistration from './components/Domainregistration';
import Applications from './components/Applications';
import Ottplatform from './components/Ottplatform';
import WhatsappBusiness from './components/WhatsappBusiness';
import Whatsapp from './components/Whatsapp';


function App() {
  return (
    <>
        <Header />
        <Digitalsignages />        
        <Consumerapi />
        <Legalservices />
        <Domainregistration />
        <Ottplatform />
        <Applications />
        <WhatsappBusiness />
        <Whatsapp />
        <Footer />
    </>
  );
}

export default App;
