import React from 'react';
import {Container, Row, Col, Carousel  } from 'react-bootstrap';
import borderTop from '../assets/img/dark-edges-top.svg';

function importH(r) {
  let images = {};
  r.keys().map((item) => ( images[item.replace('./', '')] = r(item) ));
  return images;
}
const images = importH(require.context('../assets/img/whatsapp', false, /\.(png|jpg)$/));

class WhatsappBusiness extends React.Component {
    render() {
      return (
        <>           
          <div id="whatsapp-business" className='section dark  pb-5'>
            <img className='border-top-dark' src={borderTop} alt="Dark" />
              <Container>
                <Row>
                    <Col xs={12} lg={7} className='ottplatform'>
                        <h1 className='mt-5'>
                        WHATSAPP BUSINESS PLATFORM
                        </h1>
                        <h2>Your Complete WhatsApp Marketing Tool. <span className='coloured'>Send Messages</span>, <span className='coloured'>Broadcast</span> & <span className='coloured'>Accept Payment</span> Boost Sales <span className='coloured'>Automated Messaging</span>.</h2>
                        <h3>Easy managable business platform for your communication requirements.</h3>
                        <p style={{fontSize: 20}}>
                          Kick start your Marketing with our expertise support and professional managment CRM web panel from AWTOX experts.
                        </p>
                        <div id='platform'>
                          <Row >
                            <Col>
                               <div className="list">
                                 <img className="img-fluid" src={images['greenTick.png']} alt="img1" />
                                 <span>Verified Profiles</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img-fluid" src={images['contact.png']} alt="img2" />
                                 <span>Contact Manager</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img-fluid" src={images['campaigns.png']} alt="img3" />
                                 <span>ADs & Campaigns</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                  <img className="img-fluid" src={images['bot.png']} alt="img4" />
                                  <span>Chat Bots</span>
                               </div>
                            </Col>                            
                            <Col>
                               <div className="list">
                                  <img className="img-fluid" src={images['announcment.png']} alt="img5" />
                                  <span>Announcment</span>
                               </div>
                            </Col> 
                            <Col>
                               <div className="list">
                                  <img className="img-fluid" src={images['retargeting.png']} alt="img6" />
                                  <span>Content Retargeting</span>
                               </div>
                            </Col>                  
                          </Row>
                          <Row >
                            <Col>
                              <div className="list">
                                 <img className="img-fluid d-inline" src={images['razor-pay.png']} alt="img7" />
                                 <span>Payment Gateway</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img-fluid" src={images['shopify.png']} alt="img8" />
                                 <span>eCommerce Shop</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img-fluid" src={images['zoho-logo.png']} alt="img9" />
                                 <span>Accounting & Tax</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img-fluid" src={images['woocommerce.png']} alt="img10" />
                                 <span>Wordpress Shop</span>
                               </div>
                            </Col>                   
                          </Row>
                          <Row>
                          <Col md={6}>
                              <div className="list">
                                 <img className="img-fluid" src={images['agents.png']} alt="img11" />
                                 <span>Multi Agent Facility</span>
                               </div>
                            </Col>
                            
                            <Col>
                              <div className="list">
                                  <img className="img-fluid" src={images['template.png']} alt="img12" />
                                  <span>Template Manager</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                  <img className="img-fluid" src={images['dashboard.png']} alt="img13" />
                                  <span>Content Retargeting</span>
                               </div>
                            </Col>                     
                          </Row>
                          <Row>                    
                          </Row>
                        </div>
                      </Col>
                      <Col>
                          <Carousel className="mt-5">
                            <Carousel.Item>
                              <img className="d-block w-100" src={images['banner.jpg']} alt="img14" />
                              <Carousel.Caption>
                                <h3>Web Panel</h3>
                              </Carousel.Caption>
                            </Carousel.Item>
                          </Carousel>
                      </Col>
                </Row>
                </Container>
            </div>
          
        </>
      );
    }
  }

  export default WhatsappBusiness;

