import React from 'react';
import {Container, Row, Col, Nav } from 'react-bootstrap';
import logo from '../assets/img/logo-footer.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

class Footer extends React.Component {
    render() {
      return (
        <div id='footer'>
          <Container>
            <Row>              
              <Col id='ftr-1' xs={6} sm={6} md={3}>
                <h5 className='mt-4 mb-4'>Company</h5>
                <Nav className="nav-footer flex-column">
                  <Nav.Link eventKey="link-1" tabIndex='1' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> About Us</Nav.Link>
                  <Nav.Link eventKey="link-2" tabIndex='2' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> News</Nav.Link>
                  <Nav.Link eventKey="link-3" tabIndex='3' href="https://www.awtox.com/myaccount/submitticket.php"><FontAwesomeIcon icon={faAngleDoubleRight} /> Customer Support</Nav.Link>
                  <Nav.Link eventKey="link-4" tabIndex='4' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Privacy Policy</Nav.Link>
                  <Nav.Link eventKey="link-5" tabIndex='1' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Terms Of Services</Nav.Link>
                  <Nav.Link eventKey="link-6" tabIndex='1' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Refund Policy</Nav.Link>
                </Nav>                
              </Col>
              <Col id='ftr-2' xs={6} sm={6} md={3}>
                <h5 className='mt-4 mb-4'>Services</h5>
                <Nav className="nav-footer flex-column">
                  <Nav.Link eventKey="link-7" tabIndex='7' href="#digital-signage"><FontAwesomeIcon icon={faAngleDoubleRight} /> Digital Signages</Nav.Link>
                  <Nav.Link eventKey="link-8" tabIndex='8' href="#consumer-api"><FontAwesomeIcon icon={faAngleDoubleRight} /> Consumer API</Nav.Link>
                  <Nav.Link eventKey="link-9" tabIndex='9' href="#legal-services"><FontAwesomeIcon icon={faAngleDoubleRight} /> Legal Services</Nav.Link>
                  <Nav.Link eventKey="link-10" tabIndex='10' href="#domain-registration"><FontAwesomeIcon icon={faAngleDoubleRight} /> Domain Registration</Nav.Link>
                  <Nav.Link eventKey="link-11" tabIndex='11' href="#ott-platform"><FontAwesomeIcon icon={faAngleDoubleRight} /> OTT Platform</Nav.Link>
                  <Nav.Link eventKey="link-12" tabIndex='12' href="#application-development"><FontAwesomeIcon icon={faAngleDoubleRight} /> Web | Mobile | TV Applications</Nav.Link>
                  <Nav.Link eventKey="link-13" tabIndex='13' href="#whatsapp-business"><FontAwesomeIcon icon={faAngleDoubleRight} /> Whatsapp Business</Nav.Link>
                </Nav>                
              </Col>
              <Col id='ftr-3' xs={6} sm={6} md={3}>
                <h5 className='mt-4 mb-4'>Support</h5>
                <Nav className="nav-footer flex-column">
                  <Nav.Link eventKey="link-14" tabIndex='14' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Enquiry</Nav.Link>
                  <Nav.Link eventKey="link-15" tabIndex='15' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Payment Methods</Nav.Link>
                  <Nav.Link eventKey="link-16" tabIndex='16' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Frequently Ask Questions</Nav.Link>
                  <Nav.Link eventKey="link-17" tabIndex='17' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Social Marketing</Nav.Link>
                  <Nav.Link eventKey="link-18" tabIndex='18' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> PR Marketing</Nav.Link>
                  <Nav.Link eventKey="link-19" tabIndex='19' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> AWS Migration Enquiry</Nav.Link>
                  <Nav.Link eventKey="link-20" tabIndex='20' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> RazorPay Intigration</Nav.Link>
                </Nav>                
              </Col>
              <Col id='ftr-4' xs={6} sm={6} md={3}>
                <h5 className='mt-4 mb-4'>Customer Care</h5>
                <Nav className="nav-footer flex-column">
                  <Nav.Link eventKey="link-21" tabIndex='21' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Legal</Nav.Link>
                  <Nav.Link eventKey="link-22" tabIndex='22' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Web Apps</Nav.Link>
                  <Nav.Link eventKey="link-23" tabIndex='23' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Trademark</Nav.Link>
                  <Nav.Link eventKey="link-24" tabIndex='24' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> SEO Marketing</Nav.Link>
                  <Nav.Link eventKey="link-25" tabIndex='25' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> API Services</Nav.Link>
                  <Nav.Link eventKey="link-26" tabIndex='26' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Server Managment</Nav.Link>
                  <Nav.Link eventKey="link-27" tabIndex='27' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Google Cloud Managment</Nav.Link>
                  <Nav.Link eventKey="link-28" tabIndex='28' href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> AWS Cloud Managment</Nav.Link>
                </Nav>                
              </Col>
            </Row>
            <hr />
            <Row className='align-items-center'>
              <Col className='text-center' sm={12} md={4}>
                <img className='logoFooter' src={logo} alt='AWOX Footer' />
                <div className='social'>
                    <a className='social-link fb' href='https://www.facebook.com/awtox' target='_blank' rel='noopener noreferrer'>
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a className='social-link tw' href='https://twitter.com/awtox_com' target='_blank' rel='noopener noreferrer'>
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a className='social-link ln' href='https://www.linkedin.com/company/awtox' target='_blank' rel='noopener noreferrer'>
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a className='social-link in' href='https://www.instagram.com/awtox_com' target='_blank' rel='noopener noreferrer'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a className='social-link yt' href='https://www.youtube.com/channel/UC0KL5WoHLGAONsLnntp8l0Q' target='_blank' rel='noopener noreferrer'>
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                </div>            
              </Col>
              <Col sm={12} md={8}> 
                  <Nav className="nav-bottom mb-2">
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Indian Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#South Indian Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#State Bank Of India Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Airtel Payment Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Federal Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#IDBI Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Indian Overses Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#UCO Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Axis Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#IDFC Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#HDFC Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Bank of Baroda Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#ICICI Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Indusind Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Paytm Payments Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Indian Highways Managment Company Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Karnataka Bank Fastag</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Bank of Maharastra Bank Fastag</Nav.Link>
                  </Nav> 
                  <Nav className="nav-bottom mb-2">
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Digital Gold - Buy and invest in gold.</Nav.Link>
                  </Nav>
                  <Nav className="nav-bottom mb-2">
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#AirIndia</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#SpiceJet</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#IndiGo</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Go First</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Vistara</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Air Asia</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Gulf Airways</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Emirates Airlines</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Etihad Airways</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Singapore Airlines</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Britesh Airways</Nav.Link>
                  </Nav>    
              </Col>
              <hr />
              <Col sm={12} md={12}>                
                  <Nav className="nav-bottom nav-bottom justify-content-center mb-3">
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Indian Oil Corporation Limited API</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Bharat Petroleum Corporation Limited (BPCL) API</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Hindustan Petroleum Corporation Ltd (HPCL)</Nav.Link>
                  </Nav>   
              </Col>
              <hr />
              <Col sm={12} md={12}>                
                  <Nav className="nav-bottom justify-content-center mb-3">
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Airtel Recharge</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#BSNL Recharge</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Jio Recharge</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Vodaphone/Idea Recharge</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#DTH Recharge</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Postpaid Billpayment</Nav.Link>
                  </Nav>   
              </Col>
              <hr />
              <Col sm={12} md={12}>                
                  <Nav className="nav-bottom justify-content-center mb-3">
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Munnar Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Bali Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Delhi Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Krabi Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Udaypur Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Dubai Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Goa Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Amritsar Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Agra Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Bengal Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Singapor Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Bangkok Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Maldives Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Switzerland Holiday Destinations</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Europe Holiday Destinations</Nav.Link>
                  </Nav>   
              </Col>
              <hr />
              <Col sm={12} md={12}>                
                  <Nav className="nav-bottom nav-bottom justify-content-center mb-3">
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#ICICI Prudential Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Tata AIA Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Royal Sundaram General Insurance Co. Limited</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Aditya Birla Health Insurance Co Limited</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#LIC of India</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Kotak Life Insurance Company Limited</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Aditya Birla Sun Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#ICICI Lombard General Insurance (Motor)</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#HDFC Life Insurance Co. Ltd.</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Aviva Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Bajaj Allianz Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Bharti Axa Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Canara HSBC OBC Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#DHFL Pramerica Life Insurance Co. Ltd</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Edelweiss Tokio Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Exide Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Future General India Life Insurance Company Limited</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#IDBI Federal Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#IndiaFirst Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Max Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Care Health Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Care Health Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Reliance General Insurance Company Limited</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Shriram Life Insurance Co Ltd</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Star Union Dai Ichi Life Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Max Bupa Health Insurance</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Shriram General Insurance</Nav.Link>
                  </Nav>   
              </Col>
              <hr />
              <Col sm={12} md={12}>                
                  <Nav className="nav-bottom nav-bottom justify-content-center mb-3">
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Thrissur Corporation Electricity Department</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#BSES Rajdhani Prepaid Meter Recharge</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Central Power Distribution Corporation Ltd. of Andhra Pradesh (APCPDCL)</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Kannan Devan Hills Plantations Company Private Limited</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Lakshadweep Electricity Department</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Electricity Department Chandigarh</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Telangana State Southern Power Distribution Company Ltd. (TSSPDCL)</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Sikkim Power</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#KSEB - KERALA</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#NDMC - DELHI</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Adani Electricity - MUMBAI</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Himachal Pradesh State Electricity Board</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#UHBVN - HARYANA</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#UPPCL (URBAN/RURAL) - UTTAR PRADESH</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#UGVCL - GUJARAT</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Tata Power - MUMBAI/DELHI</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Noida Power - NOIDA</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Kota Electricity Distribution - RAJASTHAN</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Assam Power Distribution Company Ltd-APDCL(RAPDR)</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#CESC - WEST BENGAL</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#CSPDCL - CHHATTISGARH</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#JUSCO - JAMSHEDPUR</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#MSEDC - MAHARASHTRA</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Torrent Power - SURAT</Nav.Link>
                  </Nav>        
              </Col>
              <hr />
              <Col sm={12} md={12}>                
                  <Nav className="nav-bottom nav-bottom justify-content-center mb-3">
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Croma eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#BigBasket eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Bigbaazar eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Crossword eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#HomeCenter eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#SparHypermarket eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Spencer eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#HipBar eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Tata CLiQ eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Make My Trip eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Clear Trip eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Thomas Cook eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Croma eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Yatra eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#The Serai eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Taj Hotels eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Thrillophillia eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#TripXOXO eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Your Holiday Dealz eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Himalaya eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Lakme Saloon eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Nautica eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Nykaa eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#VLCC eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Body Craft eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Cult Fit eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#NuAyurveda Clinic eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#The Man Company eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Titen Eye+ eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#3M Car Care eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Four Fountains Spa eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#FlipKart eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Amazon eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Myntra eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Prestige Smart Kitchen eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Urban Ladder eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Chumbak eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Speedo eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#AllenSolly eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Arrow eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#FastTrack eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Joyallukas eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Levi's eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Lifestyle eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Nyke eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Peter England eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#FabIndia eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#FBB eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Max eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Book My Show eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#PVR Cinemas eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Zee5 eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Barbeque Nation eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Beer Cafe eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Mainland China eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Starbucks eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Dominos eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Swiggy eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Zomato eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Jokey eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Brand Factory eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#Kurlon eGift Voucher</Nav.Link>
                    <Nav.Link href="#" target="_self" rel="noopener noreferrer">#TripXOXO eGift Voucher</Nav.Link>
                  </Nav>        
              </Col>
            </Row>
            <Row>
              <Col>
              <hr/>
                 <div className='footer-copyright mt-2'>
                     <p>COPYRIGHT &copy; 2023 AWTOX (OPC) PRIVATE LIMITED. ALL RIGHT RESERVED.</p>
                 </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  export default Footer;

