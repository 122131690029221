import React from 'react';
import {Container, Row, Col, Button, Card } from 'react-bootstrap';
import borderTop from '../assets/img/dark-edges-top.svg';
import borderBottom from '../assets/img/dark-edges-bottom.svg';

function importH(r) {
  let images = {};
  r.keys().map((item) => ( images[item.replace('./', '')] = r(item) ));
  return images;
}
const images = importH(require.context('../assets/img/legal', false, /\.(png)$/));

class Legalservices extends React.Component {
    render() {
      return (
        <>  
          
          <div id="legal-services" className='section dark'>
            <img className='border-top-dark' src={borderTop} alt="dark" />
              <Container>
                  <Row>
                      <Col xs={12} md={8} className='legalservices'>
                        <h1 className='mt-5'>
                          LEGAL SERVICES
                        </h1>
                        <h3><span className='coloured'>Legal:</span> Your documents, contracts, and registrations handled by expert lawyers.</h3>
                        <h3><span className='coloured'>Tax:</span> Filing returns and paying your taxes, made easier for you.</h3>
                        <h3><span className='coloured'>Compliance:</span> Our chartered accountants & company secretaries will keep your books in order.</h3>
                        <div id='legal' className="mb-4">
                          <Row >
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['company_registration.png']} alt="img1" />
                                 <span>Company Registration</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['company_name_search.png']} alt="img2" />
                                 <span>Company Name Search</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['licenses_registrations.png']} alt="img3" />
                                 <span>Licenses & Registrations</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['corporate_compliance.png']} alt="img4" />
                                 <span>Corporate Compliance</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['roc_amendments.png']} alt="img5" />
                                 <span>ROC Amendments</span>
                               </div>
                            </Col>                            
                          </Row>
                          <Row>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['mandatory_annual_filings.png']} alt="img6" />
                                 <span>Mandatory Filings</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <img className="img" src={images['accounting_tax.png']} alt="img7" />
                                 <span>Accounting & Tax</span>
                               </div>
                            </Col>                           
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['labour_compliance.png']} alt="img8" />
                                 <span>Labour Compliance</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['trademark.png']} alt="img9" />
                                 <span>Trademark Filings</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['gst_tax.png']} alt="img10" />
                                 <span>GST Filings</span>
                               </div>
                            </Col>                            
                          </Row>
                          <Row>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['patient.png']} alt="img11" />
                                 <span>Patient Registration</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['copyright.png']} alt="img12" />
                                 <span>Copyright Registration</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['pitch_deck.png']} alt="img13" />
                                 <span>Pitch Deck</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['startup_registration.png']} alt="img14" />
                                 <span>Startup Registration</span>
                               </div>
                            </Col>
                            <Col>
                               <div className="list">
                                 <img className="img" src={images['seed_funding.png']} alt="img15" />
                                 <span>Seed Fundings</span>
                               </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xs={12} md={4} className='legal'>
                        <Row>
                          <Col>
                            <Card className="mt-4">
                                <Card.Body>
                                  <Card.Title>Company Registration</Card.Title>
                                  <Card.Text>
                                     Register your business India, & Abroad with experts. 
                                  </Card.Text>
                                  <h4>@STARTING ₹24,999/- <span className="small">INR</span></h4>
                                  <div className="d-grid gap-2"> 
                                    <Button variant="success" size="sm">Contact Now</Button>
                                  </div>
                                </Card.Body>
                              </Card>
                          </Col>
                          <Col>
                            <Card className="mt-4">
                                <Card.Body>
                                  <Card.Title>GST Monthly Filings</Card.Title>
                                  <Card.Text>
                                    File your monthly GST with our prompt services with us.
                                  </Card.Text>
                                  <h4>@STARTING ₹999/- <span className="small">INR</span></h4>
                                  <div className="d-grid gap-2"> 
                                    <Button variant="success" size="sm">Contact Now</Button>
                                  </div>
                                </Card.Body>
                              </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card className="mt-3 mb-4">                                
                                <Card.Body>
                                  <Card.Title>TradeMark Filings</Card.Title>
                                  <Card.Text>
                                    Get a quick service from our team and secure your business trades.
                                  </Card.Text>
                                  <h4>@STARTING ₹6,999/- <span className="small">INR</span></h4>
                                  <div className="d-grid gap-2"> 
                                    <Button variant="success" size="sm">Contact Now</Button>
                                  </div>
                                </Card.Body>
                              </Card>
                          </Col>
                          <Col>
                            <Card className="mt-3 mb-4">
                                <Card.Body>
                                  <Card.Title>Digital Signatures <small>(DSC)</small></Card.Title>
                                  <Card.Text>
                                    Get your Digital Signature Certificate & Sign Documents with us.
                                  </Card.Text>
                                  <h4>@STARTING ₹1,999/- <span className="small">INR</span></h4>
                                  <div className="d-grid gap-2"> 
                                    <Button variant="success" size="sm">Contact Now</Button>
                                  </div>
                                </Card.Body>
                              </Card>
                          </Col>
                        </Row>
                      </Col>
                  </Row>
                </Container>
                <img className='border-bottom-dark invert' src={borderBottom} alt='invert' />
            </div>
          
        </>
      );
    }
  }

  export default Legalservices;

