import React from 'react';
import {Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faAppStoreIos, faChrome, faFirefoxBrowser, faInternetExplorer, faWindows, faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

class Applications extends React.Component {
    render() {
      return (
        <>
         <div id="application-development" className='section'>
                <Container>
                <Row>
                    <Col xs={12} md={7} className="digitalsignage mt-4 mb-5">
                        <h1 className='mt-5 mb-2'>
                          <strong>WEB | MOBILE | TV APPLICATIONS</strong>
                        </h1>
                        <h2>Business Portfolio, <span className='coloured'>eCommerce</span>, Billing Applications, <span className='coloured'>Travel</span>, Service, Grocery, <span className='coloured'>Food Delivery</span>...</h2>
                        <h3>We are the professional developers with expertise business expansion and managment experiences.</h3>
                        <p style={{fontSize: 20}}>
                           Partner with our technology thats makes a hassel free support for your business and services to get on to profit margin with latest advantages of internet and technology.
                        </p>
                        <p style={{fontSize: 20}}>
                            Our experience in the industry of web and technology to help our clients for a better growth.
                        </p>                        
                      </Col>
                      <Col>
                      <div id='signage' className="mt-4">
                          <Row >
                            <Col>
                               <div className="list">
                                 <FontAwesomeIcon icon={faAndroid} />
                                 <span>Android</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <FontAwesomeIcon icon={faAppStoreIos} />
                                 <span>Apple IOS</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <FontAwesomeIcon icon={faWindows} />
                                 <span>Windows</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <FontAwesomeIcon icon={faChrome} />
                                 <span>Chrome</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <FontAwesomeIcon icon={faInternetExplorer} />
                                 <span>Microsoft Edge</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <FontAwesomeIcon icon={faFirefoxBrowser} />
                                 <span>FireFox</span>
                               </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="list">
                                 <FontAwesomeIcon icon={faFacebook} />
                                 <span>Facebook</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <FontAwesomeIcon icon={faTwitter} />
                                 <span>Twitter</span>
                               </div>
                            </Col>
                            <Col>
                              <div className="list">
                                 <FontAwesomeIcon icon={faLinkedin} />
                                 <span>LinkedIn</span>
                               </div>
                            </Col>                      
                          </Row>
                        </div>
                      </Col>
                </Row>
                </Container>
            </div>  
    
        </>
      );
    }
  }

  export default Applications;

