import React from 'react';
import {Container, Row, Col } from 'react-bootstrap';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function importT(r) {
  return r.keys().map(r);
}
function importH(r) {
  let images = {};
  r.keys().map((item) => ( images[item.replace('./', '')] = r(item) ) );
  return images;
}
const tlds = importT(require.context('../assets/img/tlds', false, /\.(png)$/));
const hosting = importH(require.context('../assets/img/hosting', false, /\.(png)$/));

class Domainregistration extends React.Component {
    render() {
      return (
        <>        
         <div id="domain-registration" className='section'>
                <Container>
                  <Row>
                      <Col xs={12} md={7} className="domainSearch mb-5 mt-5 pt-4">
                        <h1>
                          DOMAIN REGISTRATION
                        </h1>
                        <h2>Book a <span className='coloured'>domain</span> for your business! <br />Get <span className='coloured'>business mails</span> or <span className='coloured'>google workspaces</span> and publish your <span className='coloured'>websites</span> and <span className='coloured'>apps</span> with us.</h2>
                        <InputGroup size="xxl" className="mb-3 mt-4">
                          <Form.Control
                            placeholder="eg: awtox.com"
                            aria-label="Book your domain now"
                            aria-describedby="basic-addon2"
                          />
                          <Button variant="outline-secondary" id="button-addon2">
                            <FontAwesomeIcon icon={faMagnifyingGlass} /> Search 
                          </Button>
                        </InputGroup>
                        <div id='tlds'>
                          <Row >
                            <Col>
                               <span><img className='img' src={tlds[0]} alt='01' /></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[1]} alt='02'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[2]} alt='03'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[3]} alt='04'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[4]} alt='05'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[5]} alt='06'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[6]} alt='07'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[7]} alt='08'/></span>
                            </Col>
                          </Row>
                          <Row >
                            <Col>
                               <span><img className='img' src={tlds[8]} alt='09'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[9]} alt='10'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[10]} alt='11'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[11]} alt='12'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[12]} alt='13'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[13]} alt='14'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[14]} alt='15'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[15]} alt='16'/></span>
                            </Col>
                          </Row>
                          <Row >
                            <Col>
                               <span><img className='img' src={tlds[16]} alt='17'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[17]} alt='18'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[18]} alt='19'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[19]} alt='20'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[20]} alt='21'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[21]} alt='22'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[22]} alt='23'/></span>
                            </Col>
                            <Col>
                               <span><img className='img' src={tlds[23]} alt='24'/></span>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xs={12} md={5} className='hostingListing mb-5'>
                        <Row>
                          <Col xs={6} sm={6}>
                            <div className='service-list'>
                              <img className='icon' src={hosting['linux.png']} alt="img1" />
                              <h5>Linux Hosting</h5>
                            </div>
                          </Col>
                          <Col xs={6} sm={6}>
                            <div className='service-list'>
                              <img className='icon' src={hosting['windows.png']} alt="img2" />
                              <h5>Windows Hosting</h5>
                            </div>
                          </Col>
                          <Col xs={6} sm={6}>
                            <div className='service-list'>
                              <img className='icon' src={hosting['dedicated.png']} alt="img3" />
                              <h5>Dedicated Servers</h5>
                            </div>
                          </Col>
                          <Col xs={6} sm={6}>
                            <div className='service-list'>
                              <img className='icon' src={hosting['business-email.png']} alt="img4" />
                              <h5>Business Email's</h5>
                            </div>
                          </Col>
                          <Col xs={6} sm={6}>
                            <div className='service-list'>
                              <img className='icon' src={hosting['google.png']} alt="img5" />
                              <h5>Google Workspace</h5>
                            </div>
                          </Col>
                          <Col xs={6} sm={6}>
                            <div className='service-list'>
                              <img className='icon' src={hosting['ssl.png']} alt="img6" />
                              <h5>SSL Certificates</h5>
                            </div>
                          </Col>
                          <Col xs={6} sm={6}>
                            <div className='service-list'>
                              <img className='icon' src={hosting['wordpress.png']} alt="img7" />
                              <h5>Wordpress Hosting</h5>
                            </div>
                          </Col>
                          <Col xs={6} sm={6}>
                            <div className='service-list'>
                              <img className='icon' src={hosting['ecommerce.png']} alt="img8" />
                              <h5>eCommerce Hosting</h5>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                  </Row>
                </Container>
            </div>             
        </>
      );
    }
  }

  export default Domainregistration;

